import React ,  { Component } from "react";



class Feature extends Component{
    render(){
       let data = [
           
        {
            image_path:'AUTOMATED_FUNNEL.png',
            icon: 'zmdi zmdi-collection-text',
            title: 'AI BOOKING AGENT',
            desc: 'The real star of the program is your AI booking agent. Day or night “Sara” communicates with new leads via voice, SMS, and email. Sara is amazing!'
        },

        {
            image_path:'PROVEN_ADS.png',
            icon: 'zmdi zmdi-sun',
            title: 'FACEBOOK VIDEO ADS',
            desc: 'Our Facebook video ads run at 2X the lead results when compared to the static image ads of all of our competitors. You will get 2X the lead results for the same ad spend.'
        },

        {
            image_path:'EMAIL_SMS_PHONE.png',
            icon: 'zmdi zmdi-brush',
            title: 'VOICE, SMS & EMAIL',
            desc: 'The program automatically connects to each new lead via email and sms messaging and astoundingly human AI voice calls. There is nothing else like it.'
        },

        {
            image_path:'CONVERSATIONS.png',
            icon: 'zmdi zmdi-desktop-mac',
            title: 'ALL DONE-FOR-YOU',
            desc: 'At every step, we do it all for you. There is no other program like it to ever be offered. Videos ads, and AI booking agent and leads converted to bookings.'
        },

        {
            image_path:'INAPP_FB_ADS.png',
            icon: 'zmdi zmdi-language-html5',
            title: 'CONFIRMED BOOKINGS',
            desc: 'Your dedicated agent will also confirm each of your new bookings to reduce the number of cancellations and no-shows.'
        }
       ] 

       let DataList = data.map((val , i) => {
           return(
               /* Start Single Feature */
               <div className="feature" key={i}>
                   <div className="feature-icon">
                       {/*<i className={`${val.icon}`}/>*/}
                       <img className="iconset-common-3" src={require('../assets/images/app/'+val.image_path)} alt="BOOKINGS"/>
                   </div>
                   <div className="content">
                       <h4 className="title">{val.title}</h4>
                       <p className="desc">{val.desc}</p>
                   </div>
               </div>
               /* End Single Feature */
           )
       })

       return(
           <div className= {`feature-area feature-bg-image pb--50 ${this.props.horizontalfeature}`} id="features">
               <div className="container">
                   <div className="row">
                       <div className="col-lg-12">
                            <div className="section-title text-center mb--40">
                                <h2>PROGRAM <span className="theme-color">FEATURES</span></h2>
                                <img className="image-1" src={require('../assets/images/app/title-icon.png')} alt="App Landing"/>
                                <img className="image-2" src={require('../assets/images/app/title-icon-2.png')} alt="App Landing"/>
                                <img className="image-3" src={require('../assets/images/app/title-icon-3.png')} alt="App Landing"/>
                                <p>The MAZUR Program is the most successful Medspa or Wellness Clinic marketing program and is the only program to offer premium video ads and an AI booking agent.
                                </p>
                            </div>
                       </div>
                   </div>
                   <div className="row mt--30">
                       <div className="col-lg-7 offset-lg-5">
                            <div className="feature-list">
                                {DataList}
                            </div>
                       </div>
                   </div>
               </div>
           </div>
        )
    }
}

export default Feature;
