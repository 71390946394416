import React ,  { Component } from "react";

class About extends Component{
    render(){
        return(
            <div className={`app-about ${this.props.horizontalabout}`}  id="about">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title text-center mb--40">
                                <h2>VIDEO ADS. <span className="theme-color">AI BOOKING.</span></h2>
                                <img className="image-1" src={require('../assets/images/app/title-icon.png')} alt="App Landing"/>
                                <img className="image-2" src={require('../assets/images/app/title-icon-2.png')} alt="App Landing"/>
                                <img className="image-3" src={require('../assets/images/app/title-icon-3.png')} alt="App Landing"/>
                                <p>As a medspa or wellness clinic you have no time for ads, leads or bookings. We do every step for you and send new fresh service bookings to your email. 
</p>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-lg-5 offset-lg-1 mt--40">
                            <div className="about-thumbnail mr--35">
                                <img className="image-1" src={require('../assets/images/app/Image_2.png')} alt="App Landing"/>
                                <img className="image-2" src={require('../assets/images/app/Image_2.png')} alt="App Landing"/>
                                <img className="image-3" src={require('../assets/images/app/Image_2.png')} alt="App Landing"/>
                            </div>
                        </div>
                        <div className="col-lg-6 mt--40">
                            <div className="about-content">
                                <h2 className="title">WE DO IT <span className="theme-color">ALL.</span></h2>
                                <p>From our attention-grabbing video ads to nurturing leads and driving bookings, we do it all for you. 
You  have access to a steady stream of new clients, a seamless booking system, and our entire library of licensed video ads; all managed for you. Each MAZUR Program member has their own dedicated MAZUR booking agent. Your agent is a customized medspa and wellness AI agent focussed on taking every new lead and turning each lead into a new booking.
</p>
                                <p>Imagine focusing on what you do best while the MAZUR Program works tirelessly to bring new clients through your doors. We do it all for you from start to finish. No more trying to manage your own ads, trying to call leads or trying to confirm a new booking.</p>
                                <div className="about-buttons">
                                    {/*<a href="https://app.mazurprogram.com"><button type="button" className="button-default button-olive button-border">Download</button></a>*/}
                                    <a href="#download"><button type="button" className="button-default button-olive button-border">Download</button></a>
                                    <a className="button-default button-border" href="#support" role="button">BOOK DEMO</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default About;











