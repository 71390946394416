import React, { Component } from "react";


class HeroOlive extends Component {
    render(){
        return(
            <div className={`slider-area bg-color ${this.props.horizontal} ${this.props.bgshape}`} id="home"  style={{background:'#2d3e50'}}>
                <div className="container h-100">
                    <div className="row">
                        <div className="col-lg-7 h-100">
                            <div className="banner-text">
                                <div className="banner-table-cell">
                                    {/*<h1>AWESOME <br /> <span className="theme-color">apnew</span> MOBILE APP.</h1>
                                    <p>There are many variations of passages of Lorem Ipsum available, but the majorityhave suffered alteration in some form, by injected humour, or randomised words which don't look even There are many variations of passages of Lorem Ipsum available</p>*/}
                                    
                                    <h1><span className="theme-color">MEDSPA </span><br /> BOOKING AGENT<br /> & VIDEO AD EXPERTS</h1>
                                    <p>The only Medspa & Wellness marketing program in the United States that uses premium video Facebook ads to drive new leads to your account every day. Our AI booking agent uses the combination of voice phone calls, SMS and email to convert each lead into a new booking.
</p>
                                    
                                    <div className="banner-buttons">
                                        <a href="#download"><button type="button" className="button-default button-olive">Download</button></a>
                                        <a className="button-default button-white" href="#support" role="button">BOOK DEMO</a>
                                    </div>
                                </div>
                            </div>

                            <div className="banner-apps">
                                {/* Start Sinhle app */}
                                <div className="single-app">
                                    <div className="single-app-table-cell">
                                        {/*<i className="zmdi zmdi-apple"></i>*/}
                                        <img className="iconset-common-1" src={require('../assets/images/app/ads.png')} alt="ADS"/>
                                        <h4>ADS</h4>
                                        <h3></h3>
                                    </div>
                                </div>

                                {/* Start Sinhle app */}
                                <div className="single-app">
                                    <div className="single-app-table-cell">
                                        {/*<i className="zmdi zmdi-cloud-download"></i>*/}
                                        <img className="iconset-common-1" src={require('../assets/images/app/BOOKINGS.png')} alt="BOOKINGS"/>
                                        <h4>BOOKINGS</h4>
                                        <h3></h3>
                                    </div>
                                </div>

                                {/* Start Single app */}
                                <div className="single-app">
                                    <div className="single-app-table-cell">
                                        {/*<i className="zmdi zmdi-android"/>*/}
                                        <img className="iconset-common-1" src={require('../assets/images/app/SAVINGS.png')} alt="SAVINGS"/>
                                        <h4>SAVINGS</h4>
                                        <h3></h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <div className="banner-product-image text-right">
                                <img className="image-1" src={require('../assets/images/app/banner-mobile.png')} alt="App Landing"/>
                                <img className="image-2" src={require('../assets/images/app/banner-mobile.png')} alt="App Landing"/>
                                <img className="image-3" src={require('../assets/images/app/banner-mobile.png')} alt="App Landing"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default HeroOlive;








